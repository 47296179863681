import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [menuActive, setMenuActive] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [resultCount, setResultCount] = useState(null);

  //

  return (
    <AppContext.Provider
      value={{
        menuActive,
        setMenuActive,
        resetSearch,
        setResetSearch,
        resultCount,
        setResultCount
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
