import React, { useContext, useEffect } from "react";
import tw, { css } from "twin.macro";
import PropTypes from "prop-types";
import { DocumentContext } from "~context/DocumentContext.jsx";

const ScreenHeight = ({ children, styles }) => {
  const { windowHeight } = useContext(DocumentContext);
  // const { windowHeight, windowWidth } = useContext(DocumentContext);

  // useEffect(() => {
  //   if (typeof window === `undefined` || typeof document === `undefined`) {
  //     return;
  //   }

  //   document.documentElement.style.setProperty(
  //     `--vh`,
  //     `${windowHeight * 0.01}px`
  //   );
  // }, [windowHeight, windowWidth]);

  //

  return (
    <section
      css={[
        ...styles,
        css`
          height: 100vh;
          height: ${windowHeight}px;
        `
      ]}
    >
      {children}
    </section>
  );
};

ScreenHeight.defaultProps = {
  styles: []
};

ScreenHeight.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ScreenHeight;
