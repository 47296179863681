/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import csv from "async-csv";

export const CacheContext = createContext({});

const CacheProvider = ({ children }) => {
  const [diamonds, setDiamonds] = useState([]);
  const [diamondCSV, setDiamondCSV] = useState(null);
  const [diamondImages, setDiamondImages] = useState(null);
  const [downloadingCSV, setDownloadingCSV] = useState(null);
  const [enriched, setEnriched] = useState(false);

  const readCSV = async (csvString, excludedIds) => {
    const rows = await csv.parse(csvString, { columns: true });
    const additionalDiamonds = [];

    rows.forEach((diamond) => {
      const id = diamond[`Diamond ID`];

      if (!id || excludedIds.includes(id)) {
        return;
      }

      additionalDiamonds.push({
        node: {
          Diamond_ID: id,
          //
          // Quality_Score: diamond[`Quality Score`],
          Size_Score: diamond[`Size Score`],
          Total_Score: diamond[`Total Score`],
          //
          Color: diamond[`Color Selector Name`],
          Color_Name: diamond[`Color Name`],
          Color_Intensity: diamond[`Color Intensity`],
          Clarity: diamond[`Clarity Name`],
          Carat: diamond.Carat,
          Cost: diamond.Cost,
          Crown_Angle: diamond[`Crown Angle`],
          Cut_Grade: diamond[`Cut Grade`],
          Depth_: diamond[`Depth%`],
          Fluorescence: diamond.Fluorescence,
          In_Person: diamond[`See In Person`],
          Lab: diamond.Lab,
          Polish: diamond.Polish,
          Ratio: diamond.Ratio,
          Shape: diamond.Shape,
          Supplier_ID: diamond[`Supplier ID`],
          Supplier_Standard: diamond[`Supplier Stage`],
          Symmetry: diamond.Symmetry,
          Table_: diamond[`Table%`],
          Type: diamond.Type
        }
      });
    });

    console.log(
      `CSV parse complete; added ${additionalDiamonds.length} diamonds`
    );

    setEnriched(true);
    setDiamonds([...diamonds, ...additionalDiamonds]);
  };

  useEffect(() => {
    if (!diamondCSV || downloadingCSV) {
      return;
    }

    setDownloadingCSV(true);
  }, [diamondCSV]);

  useEffect(() => {
    if (!downloadingCSV) {
      return;
    }

    const excludedIds = [];

    if (diamonds?.[0]) {
      diamonds.forEach(({ node }) => {
        excludedIds.push(node.Diamond_ID);
      });
    }

    axios.get(diamondCSV).then(({ data }) => {
      readCSV(data, excludedIds);
    });
  }, [downloadingCSV]);

  //

  return (
    <CacheContext.Provider
      value={{
        diamonds,
        setDiamonds,
        diamondCSV,
        setDiamondCSV,
        diamondImages,
        setDiamondImages,
        enriched,
        setEnriched
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

CacheProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CacheProvider;
