/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import tw, { css, theme } from "twin.macro";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import Go from "~components/Go.jsx";
import ScreenHeight from "~components/ScreenHeight.jsx";

const Nav = () => {
  const { menuActive, setMenuActive, setResetSearch } = useContext(AppContext);
  const { isDesktop } = useContext(DocumentContext);

  //

  if (isDesktop()) {
    return <></>;
  }

  return (
    <ScreenHeight
      styles={[
        tw`w-screen fixed top-0 right-0 bottom-0 left-0 z-30 pointer-events-none`
      ]}
    >
      <div
        role="presentation"
        onClick={() => setMenuActive(false)}
        css={[
          css`
            transition: 0.3s ${A.EASING_CUBIC} opacity;

            opacity: ${menuActive ? `.5` : `0`};
            pointer-events: ${menuActive ? `auto` : `none`};
          `,

          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 bg-black`
        ]}
      />

      <div
        css={[
          css`
            transition: 0.3s ${A.EASING_CUBIC} transform;

            transform: translate3d(${menuActive ? `0` : `-100%`}, 0, 0);

            width: 75vw;
            height: 100%;
            padding-top: 64px;
            pointer-events: ${menuActive ? `auto` : `none`};
            background-color: ${theme`colors.white`};
            color: ${theme`colors.koamaru`};
          `,
          tw``
        ]}
      >
        <ul
          css={[
            css`
              padding: 24px 30px;
            `,
            tw`relative`
          ]}
        >
          <li css={[tw`h-12 relative flex items-center justify-start`]}>
            <Go to="https://www.frankdarling.com/try-at-home">
              <T.Body>Try At Home</T.Body>
            </Go>
          </li>

          <li css={[tw`h-12 relative flex items-center justify-start`]}>
            <Go to="https://www.frankdarling.com/collections">
              <T.Body>Collections</T.Body>
            </Go>
          </li>

          <li css={[tw`h-12 relative flex items-center justify-start`]}>
            <Go to="https://www.frankdarling.com/rings">
              <T.Body>Shop Rings</T.Body>
            </Go>
          </li>

          <li css={[tw`h-12 relative flex items-center justify-start`]}>
            <button
              type="button"
              onClick={() => {
                if (typeof window !== `undefined`) {
                  window.scrollTo({
                    top: 0,
                    behavior: `smooth`
                  });
                }

                setMenuActive(false);
                setResetSearch(true);
              }}
            >
              <T.Body>Shop Diamonds</T.Body>
            </button>
          </li>

          <li css={[tw`h-12 relative flex items-center justify-start`]}>
            <Go to="https://www.frankdarling.com/how-it-works">
              <T.Body>How It Works</T.Body>
            </Go>
          </li>

          <li css={[tw`h-12 relative flex items-center justify-start`]}>
            <Go to="https://www.frankdarling.com/etiquette">
              <T.Body>Etiquette</T.Body>
            </Go>
          </li>
        </ul>
      </div>
    </ScreenHeight>
  );
};

export default Nav;
