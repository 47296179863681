/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "lazysizes";

import React from "react";
import AppProvider from "~context/AppContext.jsx";
import CacheProvider from "~context/CacheContext.jsx";
import DocumentProvider from "~context/DocumentContext.jsx";
import Header from "~components/Header.jsx";
import Nav from "~components/Nav.jsx";

import "./src/assets/css/tailwind.build.css";

export const wrapRootElement = ({ element }) => (
  <>
    <DocumentProvider>
      <AppProvider>
        <CacheProvider>
          <Header />

          <Nav />

          {element}
        </CacheProvider>
      </AppProvider>
    </DocumentProvider>
  </>
);
